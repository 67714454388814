import { useEffect, useState } from 'react'
import { client } from '../../../../types/client'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-tailwind/react'
import { PencilIcon } from '@heroicons/react/24/outline'
export default function InfoTable(props: {
  clientDoc: client
  editMode: boolean
}) {
  type field_obj = {
    client_key: string
    display_name: string
    type: string
  }

  useEffect(() => {
    if (setupMetaData) {
      setSetupMetaData(false)
      getMetadata()
    }
  })

  async function getMetadata() {
    var list_fields: field_obj[] = []
    var marketing_fields: field_obj[] = []
    var general_fields: field_obj[] = []

    await getDoc(doc(db, 'settings', 'client_fields')).then((querySnapshot) => {
      if (querySnapshot.exists()) {
        const data = querySnapshot.data()
        if (data['fields']) {
          data.fields.forEach((field_doc) => {
            if (field_doc.type) {
              switch (field_doc.type) {
                case 'marketing':
                  marketing_fields.push(field_doc)
                  break
                case 'string':
                  general_fields.push(field_doc)
                  break
                case 'list':
                  list_fields.push(field_doc)
                  break
                default:
                  console.log('Could not find category for: ', field_doc)
              }
            }
          })
        }
      }
      setListFields(list_fields)
      setGeneralFields(general_fields)
    })
  }

  const [setupMetaData, setSetupMetaData] = useState(true)
  const [generalFields, setGeneralFields] = useState<undefined | field_obj[]>()
  const [listFields, setListFields] = useState<undefined | field_obj[]>()

  return (
    <>
      <div className="h-fit w-full flex flex-row flex-wrap justify-between text-black text-md px-10">
        <div className="flex flex-col px-2">
          {generalFields && (
            <div className="w-fit h-fit">
              {generalFields?.map((obj) => (
                <div>
                  <div className="flex-row w-full flex justify-between">
                    <div className="font-semibold px-2  py-1">
                      {obj.display_name}
                    </div>
                    <div className="font-normal px-2 py-1">
                      {props.clientDoc[obj.client_key]
                        ? props.clientDoc[obj.client_key]
                        : ''}
                    </div>
                  </div>
                  <hr></hr>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex w-fit flex-col justify-between px-2">
          {listFields && (
            <div className="w-fit flex flex-row px-4">
              {listFields?.map((obj) => (
                <div className="flex-row">
                  <div className="flex-col w-full flex justify-between">
                    <div className="font-semibold px-10 py-1">
                      {obj.display_name}
                    </div>
                    <hr></hr>
                    <div className="font-normal px-2 py-1">
                      {props.clientDoc[obj.client_key] && (
                        <>
                          {props.clientDoc[obj.client_key].map((list_item) => (
                            <div>
                              <div className="p-1">{list_item}</div>
                              <hr></hr>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='w-full flex flex-row-reverse'>
          {props.editMode && (
            <Link to={'/edit/client#' + String(props.clientDoc.id)}>
              <IconButton placeholder="." variant="text">
                <PencilIcon className="h-6 w-6"></PencilIcon>
              </IconButton>
            </Link>
          )}</div>
        </div>
      </div>
      <div className="p-2">
        <hr></hr>
      </div>
    </>
  )
}

/*
    <Accordion open={accordionOpen} placeholder={'.'}>
      <AccordionHeader
        placeholder={'.'}
        onClick={() => {
          setAccordionOpen(!accordionOpen)
        }}
      >
        General Information
      </AccordionHeader>
      <AccordionBody>
      </AccordionBody>
    </Accordion>

*/

/*
Marketing Temp Set Aside.
          <div className="flex w-fit flex-col p-2">
            {marketingFields && (
              <div className="w-fit">
                {marketingFields?.map((obj) => (
                  <div>
                    <div className="flex-row w-full flex justify-between">
                      <div className="font-semibold px-2  py-1">
                        {obj.display_name}
                      </div>
                      <div className="font-normal px-2 py-1">
                        {props.clientDoc[obj.client_key]
                          ? props.clientDoc[obj.client_key]
                          : ''}
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                ))}
              </div>
            )}
          </div>
*/
