import { permission } from '../../types/permission'

export default function ensurePermissionType(obj) {
  var permissions_template: permission = {
    title: 'Default',
    add_accounts: false,
    edit_accounts: false,
    edit_my_clients_accounts: false,
    edit_current_user_account: false,
    add_clients: false,
    edit_all_clients: false, // this refers to client info not neccessarily to contacts or roles
    edit_assigned_clients: false, // this refers to client info not neccessarily to contacts or roles
    view_tiers: false,
    edit_payments: false,
    view_payments: false,
    view_my_client_payments: false,
    edit_all_roles: false,
    edit_my_client_roles: false,
    edit_all_contacts: false,
    edit_my_client_contacts: false,
    view_settings: false,
    edit_presets: false,
    manage_accounts: false,
    edit_permissions: false,
    edit_marketing: false,
    view_marketing: false,
  }

  Object.keys(permissions_template).forEach((key) => {
    if (Object.keys(obj).includes(key)) {
      permissions_template[key] = obj[key]
    }
  })
  return permissions_template
}
