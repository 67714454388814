import { TrashIcon } from '@heroicons/react/24/outline'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Tooltip,
} from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { additionalInfo } from '../../../../../types/additionalInfo'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../../../../firebase'

export default function Links(props: {
  allInfo: additionalInfo
  client_id: string
  reloadParent: Function
}) {
  const allInfo = props.allInfo
  const client_id = props.client_id

  const [editLinks, setEditLinks] = useState(false)
  const [dialogOpen, setDialog] = useState(false)
  const [linkTitle, setLinkTitle] = useState('')
  const [linkContent, setLinkContent] = useState('')
  const [linkFieldsPopulated, setLinkFieldsPopulated] = useState(false)
  const [linkToReplace, setLinkToReplace] = useState<undefined | string>()

  function editExistingLink(title) {
    setLinkTitle(title)
    setLinkToReplace(title)
    setLinkContent(
      allInfo.links && allInfo.links[title] ? allInfo.links[title] : ''
    )
  }
  async function closeAndSaveModal() {
    if (linkContent.includes('https://')) {
      try {
        var new_links_obj = {}
        if (allInfo !== undefined && allInfo.links !== undefined) {
          for (let key in allInfo.links) {
            if (linkToReplace !== undefined && key !== linkToReplace) {
              new_links_obj[key] = allInfo.links[key]
            }
          }
        }
        setLinkToReplace(undefined)
        new_links_obj[linkTitle] = linkContent
        const newInfoFile = { ...allInfo, links: new_links_obj }
        console.log(new_links_obj)
        console.log(newInfoFile)
        await setDoc(
          doc(db, 'additional_client_info', client_id),
          newInfoFile
        ).then(() => {
          setTimeout(() => {
            props.reloadParent()
          }, 100)
        })
      } catch (err) {
        alert(
          'An error occured while saving. Please try again or contact support.'
        )
      }
      setDialog(false)
      setLinkTitle('')
      setLinkContent('')
    } else {
      alert('Link must contain https:// at the beginning.')
    }
  }
  function closeModal() {
    setDialog(false)
    setLinkTitle('')
    setLinkContent('')
  }
  async function removeLink(link_title: string) {
    const response = window.confirm(
      'You are about to delete link:\n' + link_title + '\nAre you sure?'
    )
    if (response) {
      try {
        if (allInfo.links !== undefined) {
          var currentLinks = allInfo.links
          delete currentLinks[link_title]
          await setDoc(doc(db, 'additional_client_info', String(client_id)), {
            ...allInfo,
            links: currentLinks,
          })
          setTimeout(() => {
            props.reloadParent()
          }, 500)
        } else {
          props.reloadParent()
        }
      } catch (err) {
        alert('Deletion Failed. Please Try again.')
      }
    }
  }

  useEffect(() => {
    if (linkTitle !== '' && linkContent !== '') {
      setLinkFieldsPopulated(true)
    }
  })

  return (
    <div className="h-fit w-1/2 overflow-auto p-4">
      <div className="flex flex-row px-4">
        <div className="pr-6 text-blue-800 ">
          <strong>Links</strong> <br />
        </div>
        <div className="flex w-96 flex-row">
          <Button
            placeholder="."
            variant="text"
            className="p-1 text-gray-500"
            onClick={() => {
              setEditLinks(!editLinks)
            }}
          >
            {editLinks ? <>Stop Editting</> : <>Edit</>}
          </Button>
          <div className="px-2 text-gray-500"> | </div>
          <Button
            placeholder="."
            variant="text"
            className="p-1 text-gray-500"
            onClick={() => {
              setDialog(true)
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="h-40 max-h-fit w-full min-w-fit overflow-auto rounded-lg border border-gray-300 p-2 shadow-md">
        {allInfo !== undefined &&
        allInfo.links !== undefined &&
        Object.keys(allInfo.links).length > 0 ? (
          <div className="flex flex-row flex-wrap w-full">
            {Object.keys(allInfo.links).map((title, index) => {
              var url = ''
              if (
                allInfo.links !== undefined &&
                allInfo.links[title] !== undefined
              ) {
                url = allInfo.links[title]
              }
              function redirect() {
                if (url !== '') {
                  let a = document.createElement('a')
                  a.target = '_blank'
                  a.href = url
                  a.click()
                } else {
                  alert(
                    'There seems to be an issue with that link. \nReloading the page might resolve this issue.'
                  )
                }
              }
              return (
                <div
                  key={String(title) + String(index)}
                  className="flex flex-row w-fulljustify-between p-1"
                >
                  {!editLinks ? (
                    <Tooltip content={url}>
                      <Button
                        placeholder="."
                        variant="outlined"
                        onClick={redirect}
                        className="py-1 px-2 hover:bg-green-200"
                      >
                        <div className="flex flex-row w-fit whitespace-nowrap">
                          {title}
                        </div>
                      </Button>
                    </Tooltip>
                  ) : (
                    <div className="flex flex-row">
                      <Tooltip content="Edit">
                        <Button
                          placeholder="."
                          variant="outlined"
                          onClick={() => {
                            setDialog(true)
                            editExistingLink(title)
                          }}
                          className="py-1 px-2 hover:bg-blue-200 flex flex-row"
                        >
                          <div className="flex flex-row w-fit whitespace-nowrap">
                            {title}
                          </div>
                        </Button>
                      </Tooltip>
                      <div className="h-full w-full justify-center px-2">
                        <TrashIcon
                          className="h-6 w-6 hover:bg-red-200 rounded-md"
                          onClick={() => {
                            removeLink(title)
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <div className="">No Links at this time.</div>
        )}
      </div>

      <Dialog
        placeholder="."
        open={dialogOpen}
        handler={setDialog}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader placeholder=".">Add Link</DialogHeader>
        <>
          <DialogBody placeholder=".">
            <div>
              <div className="p-4">
                <Input
                  crossOrigin={'false'}
                  label="Title"
                  type="text"
                  value={linkTitle}
                  onChange={(e) => {
                    setLinkTitle(e.target.value)
                  }}
                />
              </div>
              <div className="p-4">
                <Input
                  crossOrigin={'false'}
                  type="text"
                  label="Link"
                  value={linkContent}
                  onChange={(e) => {
                    setLinkContent(e.target.value)
                  }}
                />
              </div>
            </div>
          </DialogBody>
          <DialogFooter placeholder=".">
            {linkFieldsPopulated && (
              <>
                <div className="px-2">
                  <Button placeholder="." variant="text" onClick={closeModal}>
                    Close without saving.
                  </Button>
                </div>
                <Button
                  placeholder="."
                  color="green"
                  onClick={() => {
                    closeAndSaveModal()
                  }}
                >
                  Save Link to Client
                </Button>
              </>
            )}
          </DialogFooter>
        </>
      </Dialog>
    </div>
  )
}
