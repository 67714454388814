import {
  Card,
  Tabs,
  TabsHeader,
  TabPanel,
  Tab,
  TabsBody,
} from '@material-tailwind/react'
import { useState, useEffect, useContext } from 'react'
import Loading from '../../../components/Loading'
import {
  CurrentUserContext,
  PermissionsContext,
  ReloadDataContext,
} from '../../../contexts'
import { client } from '../../../types/client'
import UPCList from './UPCList'
import Payments from './GeneralTab/AdditionalInfo/Payments'
import { db } from '../../../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import ensureClientType from '../../../functions/ensureType/ensureClientType'
import ClientHeader from './ClientHeader'
import GeneralTab from './GeneralTab/GeneralTab'

export default function ClientViewHandler() {
  const currentUser = useContext(CurrentUserContext)
  const permissions = useContext(PermissionsContext)

  const [firstLoad, setFirstLoad] = useState(true)
  const [isMyClient, setIsMyClient] = useState<undefined | boolean>()
  const [client, setClient] = useState<client | undefined>()

  const [editMode, setEditMode] = useState(false)
  const [errorFindingClient, setErrorFindingClient] = useState<
    undefined | boolean
  >()

  // eslint-disable-next-line
  const reloadData = useContext(ReloadDataContext)
  useEffect(() => {
    if (firstLoad && reloadData) {
      reloadData()
      getClient()
      setFirstLoad(false)
    }
    if (client && isMyClient === undefined) {
      checkIsMyClient(client)
    }
  })

  // functions
  async function getClient() {
    const id = window.location.href.split('#').pop()
    const docRef = doc(db, 'clients', String(id))
    const unsub = onSnapshot(docRef, (querySnapshot) => {
      if (querySnapshot.exists()) {
        const data = querySnapshot.data()
        setClient(ensureClientType(data))
        checkIsMyClient(data.roles)
      } else {
        alert('There was an issue loading the data for this client.')
        setErrorFindingClient(true)
      }
    })
  }
  function checkIsMyClient(roles) {
    const user_id = currentUser.id
    var is_my_client = false
    Object.keys(roles).forEach((key) => {
      if (roles[key] && roles[key].includes(user_id)) {
        is_my_client = true
      }
    })
    setIsMyClient(is_my_client)
  }
  // components

  const viewPayments =
    permissions.view_payments ||
    (permissions.view_my_client_payments && isMyClient)

  return (
    <Card placeholder="." className="flex h-fit w-full flex-col rounded-lg">
      {client && isMyClient !== undefined ? (
        <div className="h-fit w-full">
          <div className="h-fit w-full">
            <div className="flex h-fit w-full flex-row flex-wrap">
              <div className="flex h-fit w-full flex-col flex-wrap  overflow-auto">
                <div className="flex h-fit w-full flex-col justify-center">
                  <ClientHeader
                    permissions={permissions}
                    isMyClient={isMyClient}
                    clientDoc={client}
                    editMode={editMode}
                  />
                  <Tabs value="General">
                    <TabsHeader
                      placeholder={'.'}
                      className="flex justify-around"
                    >
                      <Tab
                        placeholder={'.'}
                        key={'General'}
                        value="General"
                        className=" w-32"
                      >
                        General
                      </Tab>
                      {viewPayments && (
                        <Tab
                          placeholder={'.'}
                          key={'Payments'}
                          value="Payments"
                          className=" w-32"
                        >
                          Payments
                        </Tab>
                      )}
                      <Tab
                        placeholder={'.'}
                        key={'UPCs'}
                        value="UPCs"
                        className=" w-32"
                      >
                        UPCs
                      </Tab>
                    </TabsHeader>
                    <TabsBody placeholder={'.'}>
                      <TabPanel key={'General'} value="General">
                        {client && permissions && isMyClient !== undefined && (
                          <GeneralTab
                            clientDoc={client}
                            permissions={permissions}
                            isMyClient={isMyClient}
                            editMode={editMode}
                            setEditMode={setEditMode}
                          />
                        )}
                      </TabPanel>
                      <TabPanel key={'Payments'} value={'Payments'}>
                        {viewPayments && <Payments client_id={client.id} />}
                      </TabPanel>
                      <TabPanel key={'UPCs'} value={'UPCs'}>
                        <UPCList client={client} />
                      </TabPanel>
                    </TabsBody>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-96 w-96 p-10">
          <Loading />
        </div>
      )}
    </Card>
  )
}
