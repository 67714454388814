import React from 'react'
import '../App.css'
import SideBar from './SideBar'
import ActionGroup from './ActionGroup'
import { Link } from 'react-router-dom'
import logo from '../assets/img/osmg-logo-blue.png'

export default function StandardLayout(props: {
  children
}): React.ReactElement {
  return (
    <div className="h-screen max-h-screen overflow-hidden flex flex-col">
      <div className="max-w-screen h-20 bg-white drop-shadow-sm border">
        <div className="flex flex-row justify-between w-full">
          <div className="justify-start pb-4 pl-4 pt-4 basis-4/6 flex flex-row">
            {/*<IconButton
              placeholder={'.'}
              variant="text"
              className='p-1'
              onClick={() => {
                navigate(-1)
              }}
            >
              <ChevronLeftIcon className="w-8 h-8" /> // This is an in app back button, but I am removing this and trying to make the browser's button work.
            </IconButton>*/}
            <div className="h-full w-2"></div>
            <h1 className="font-serif text-blue-900 text-4xl">Orbit</h1>
            <p className="p-3 text-gray-800 overflow-visible whitespace-nowrap">
              {' '}
              / v1.2.0
            </p>
          </div>
          <div className="flex flex-row-reverse w-full pr-0 pt-2">
            <div className=" bg-gray-400 w-px h-full p-0"></div>
            <ActionGroup />
          </div>
          <Link
            to="/"
            className="flex items-center min-w-max max-h-20 fit p-2 basis-1/6 justify-end"
          >
            <img src={logo} alt="" className="w-40 p-1 h-full" />
          </Link>
        </div>
      </div>
      <div className="h-100% bg-gray-300 flex flex-row">
        <SideBar />
        <div className="p-4 w-full h-screen overflow-auto">
          <div className="h-fit w-fit min-w-full rounded-md  pb-20">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
