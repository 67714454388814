export default function clientTemplate() {
  return {
    name: '',
    active: false,
    potential_client: false,
    id: '',
    file_id: '',
    commission_file: '',
    tier: '',
    logo: '',
    num_meetings: '',
    gln: '',
    duns: '',
    k_num: '',
    start_date: '',
    num_comms: '',
    end_date: '',
    aliases: [],
    brands: [],
    contacts: {},
    roles: {},
  }
}
