import { Button, Card, Typography } from '@material-tailwind/react'
import { UserIcon } from '@heroicons/react/24/outline'
import QuestionIcon from '../../assets/icons/QuestionIcon'
import { Link } from 'react-router-dom'

export default function AccountNotFound() {
  return (
    <Card placeholder="." className="flex w-full flex-col p-4">
      <div className="flex flex-row justify-center">
        <div className="flex-row flex p-5">
          <div className="">
            <div className="h-40 w-40 p-2">
              <QuestionIcon />
            </div>
          </div>
          <div className="flex w-2/3 flex-grow flex-col justify-center pl-10 pt-3">
            <Typography placeholder="." variant="h5" color="blue-gray">
              Account Not Found
            </Typography>
          </div>
        </div>
      </div>
      <div className="w-full h-fit p-10 flex justify-center">
        <Link to="/home" replace>
          <Button placeholder={'.'}>Return Home</Button>
        </Link>
      </div>
    </Card>
  )
}
