import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Input,
  Radio,
  Typography,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import '../../App.css'
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import Loading from '../../components/Loading'
import { Link, Navigate } from 'react-router-dom'
import {
  AccountsContext,
  PermissionsContext,
  ReloadDataContext,
} from '../../contexts'
import { account } from '../../types/account'
import { permission } from '../../types/permission'
import useWindowSize from '../../functions/useWindowSize'
import { reload } from 'firebase/auth'

export default function Accounts() {
  const size = useWindowSize()
  const accounts: account[] = useContext(AccountsContext)
  const permissions: permission = useContext(PermissionsContext)
  const [searchKey, setSearchKey] = useState('all')
  const [searchOpen, setSearchOpen] = useState(false)
  const [filters, setFilters] = useState('')
  const [sortOption, setSortOption] = useState('A-Z')
  const [filterSortOpen, setFilterSortOpen] = useState(false)
  const [query, setQuery] = useState('')
  function handleSearchOpen() {
    setSearchOpen(!searchOpen)
  }
  function handlefilterSortOpen() {
    setFilterSortOpen(!filterSortOpen)
  }
  function handleChangeSearchBy(new_key) {
    setSearchKey(new_key)
  }
  function handleChangeSortBy(new_key) {
    setSortOption(new_key)
  }
  function handleChangeFilter(new_key) {
    setFilters(new_key)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }
  function search(accounts: account[]) {
    // there are three stages to the searching/sorting/filtering process
    // first is checks by search via the field selected by the user.
    // second it sorts the results by either A-Z or Z-A
    // finally it filters out based on of internal or external.
    return accounts
      .filter((account: account) => {
        if (searchKey === 'all') {
          var response = account['name']
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
          if (!response) {
            response = account['email']
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase())
          }
          if (!response) {
            if (account['title']) {
              response = account['title']
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            }
          }
          return response
        } else if (searchKey === 'phone') {
          var response = false
          if (account['phone']) {
            response = account['phone']
              .toString()
              .replace(/\D/g, '')
              .includes(query.replace(/\D/g, ''))
          } else if (!response) {
            if (account['Cell Phone']) {
              response = account['Cell Phone']
                .toString()
                .replace(/\D/g, '')
                .includes(query.replace(/\D/g, ''))
            }
          }
          return response
        } else {
          return account[searchKey]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        }
      })
      .sort((a, b) => {
        if (sortOption === 'A-Z') {
          return a.name.localeCompare(b.name)
        } else {
          return b.name.localeCompare(a.name)
        }
      })
      .filter((account) => {
        if (filters === 'internal') {
          return account.internal
        }
        if (filters === 'external') {
          return !account.internal
        } else {
          return true
        }
      })
  }

  const TABLE_HEAD = [
    'Name',
    'Work Phone',
    'Cell',
    'Email',
    'Title',
    'Internal',
  ]
  const title_min_width = 1750
  const email_min_width = 1200
  console.log(size.width)
  const [navigate, setNavigate] = useState('')

  const [firstLoad, setFirstLoad] = useState(true)
  const reloadData = useContext(ReloadDataContext)
  useEffect(() => {
    if (firstLoad && accounts) {
      reloadData()
      setFirstLoad(false)
      setReadyForRendering(true)
    }
  })
  const [readyForRendering, setReadyForRendering] = useState(false)

  return (
      <Card
        placeholder="."
        className=" flex w-fit max-w-full flex-row rounded-lg overflow-clip"
        style={{ height: '90vh' }}
      >
      {navigate !== '' && <Navigate replace to={navigate} />}
        <div className="flex w-full flex-grow basis-2/3 flex-col p-5">
          <div className="flew-row flex w-full justify-between">
            <div className="w-96">
              <Input
                crossOrigin="false"
                label="Search"
                value={query}
                onChange={handleQuery}
                icon={<MagnifyingGlassIcon />}
              />
            </div>
            <div className="w-fit px-2">
              {permissions.add_accounts === true && (
                <Link to="/add/account">
                  <Button placeholder="." variant="text">
                    Add Account
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="py-5 h-full">
              {!readyForRendering ? (
                <div className='h-full w-full flex p-2'>
                  <Loading />
                </div>
              ) : (
            <Card
              placeholder="."
              className="max-h-full pb-2 overflow-auto w-full rounded-lg border border-gray-300 shadow-md"
            >
                <table className="w-full table-auto text-left ">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => {
                        if (head === 'Title' && size.width < title_min_width) {
                          return <></>
                        }
                        if (head === 'Email' && size.width < email_min_width) {
                          return <></>
                        }
                        return (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  {accounts !== undefined ? (
                    <tbody>
                      {search(accounts).map((account, index) => {
                        const classes = 'p-2'
                        return (
                          <tr
                            key={account.name + String(index)}
                            className="hover:bg-blue-50 h-fit"
                            onClick={() =>
                              setNavigate('/view/account#' + String(account.id))
                            }
                          >
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account?.name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account.phone}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account['Cell Phone']}
                              </Typography>
                            </td>
                            {size.width > email_min_width && (
                              <td className={classes}>
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {account.email}
                                </Typography>
                              </td>
                            )}
                            {size.width > title_min_width && (
                              <td className={classes}>
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {account.title}
                                </Typography>
                              </td>
                            )}
                            <td className={classes + 'flex justify-center'}>
                              {account.internal ? (
                                <CheckCircleIcon
                                  className="h-6 w-6"
                                  color="green"
                                />
                              ) : (
                                <XCircleIcon className="h-6 w-6" color="blue" />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <>
                      <div className="p-3">
                        <Loading />
                      </div>
                    </>
                  )}
                </table>
            </Card>
              )}
          </div>
        </div>
        <div className="min-w-fit flex-grow p-5">
          <div>
            <div className="w-full">
              <Accordion placeholder="." open={searchOpen}>
                <AccordionHeader placeholder="." onClick={handleSearchOpen}>
                  Search By
                </AccordionHeader>
                <AccordionBody className="w-full">
                  <div className="flex max-h-64 w-full flex-col overflow-auto p-2">
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('all')
                      }}
                      label="All"
                      defaultChecked
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('name')
                      }}
                      label="Name"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('phone')
                      }}
                      label="Phone"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('email')
                      }}
                      label="Email"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('title')
                      }}
                      label="Title"
                    />
                  </div>
                  <hr />
                </AccordionBody>
              </Accordion>
            </div>
            <div className="w-full">
              <Accordion placeholder="." open={filterSortOpen}>
                <AccordionHeader placeholder="." onClick={handlefilterSortOpen}>
                  Sort & Filter
                </AccordionHeader>
                <AccordionBody className="w-full">
                  <div className="flex flex-row">
                    <div className="flex flex-col p-2">
                      <strong>Filter:</strong>
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('internal')
                        }}
                        label="Internal"
                      />
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('external')
                        }}
                        label="External"
                      />
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('')
                        }}
                        label="No Filters"
                        defaultChecked
                      />
                    </div>
                    <div className="flex flex-col p-2">
                      <strong>Sort:</strong>
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('A-Z')
                        }}
                        label="A-Z"
                        defaultChecked
                      />
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('Z-A')
                        }}
                        label="Z-A"
                      />
                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
            </div>
            <hr />
          </div>
        </div>
      </Card>
  )
}
