import { Card } from 'antd'
import { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'

export default function PermissionsMatchDisplay(props: {
  permissionsTemplates
  permissionsById
}) {
  function setupData() {
    var account_ids_without_regular_title: string[] = []
    var account_ids_with_misaligned_values: string[] = []
    Object.keys(props.permissionsById).forEach((account_id) => {
      const account_permissions = props.permissionsById[account_id]

      if (
        Object.keys(props.permissionsTemplates).includes(
          account_permissions.title
        )
      ) {
        var misaligned_values = false
        const perm_template =
          props.permissionsTemplates[account_permissions.title]
        Object.keys(perm_template).forEach((permission_value_key) => {
          if (
            !account_permissions[permission_value_key] ||
            account_permissions[permission_value_key] !==
              perm_template[permission_value_key]
          ) {
            misaligned_values = true
          }
        })
        if (misaligned_values) {
          account_ids_with_misaligned_values.push(account_id)
        }
      } else {
        account_ids_without_regular_title.push(account_id)
      }
    })
    setMisalignedAccounts(account_ids_with_misaligned_values)
    setAccountsWithoutRegTitle(account_ids_without_regular_title)
  }

  const [misalignedAccounts, setMisalignedAccounts] = useState<
    string[] | undefined
  >()
  const [accountsWithoutRegTitle, setAccountsWithoutRegTitle] = useState<
    string[] | undefined
  >()

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      setupData()
      setFirstLoad(false)
    }
  })

  const [reviewMisalignedAccounts, setReviewMisalingedAccounts] =
    useState(false)
  const [reviewOddTitleAccounts, setReviewOddTitleAccounts] = useState(false)

  function MisalignedTable(props: { misaligned_ids }) {
    return (
      <div className="flex flex-col">
        {props.misaligned_ids.map((account_id) => {
          return <div>{account_id}</div>
        })}
      </div>
    )
  }
  function OddTitleTable(props: { oddtitle_ids }) {
    return (
      <div className="flex flex-col">
        {props.oddtitle_ids.map((account_id) => {
          return <div>{account_id}</div>
        })}
      </div>
    )
  }

  return (
    <div className="p-4">
      <Card className="h-fit w-fit">
        <div className='font-semibold p-1'>Total Number of Accounts with Permissions: {Object.keys(props.permissionsById).length}</div>
        {misalignedAccounts && accountsWithoutRegTitle ? (
          <div>
            <div
              className="font-semibold p-1"
              onClick={() => {
                setReviewMisalingedAccounts(!reviewMisalignedAccounts)
              }}
            >
              Accounts with Misaligned Values: {misalignedAccounts.length}
            </div>
            {reviewMisalignedAccounts && (
              <>
                <div>
                  <MisalignedTable misaligned_ids={misalignedAccounts} />
                </div>
              </>
            )}
            <div
              className="font-semibold p-1"
              onClick={() => {
                setReviewOddTitleAccounts(!reviewOddTitleAccounts)
              }}
            >
              Accounts without Regular Titles: {accountsWithoutRegTitle.length}
            </div>
            {reviewOddTitleAccounts && (
              <>
                <div>
                  <OddTitleTable oddtitle_ids={accountsWithoutRegTitle} />
                </div>
              </>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </Card>
    </div>
  )
}
