import AccountIcon from '../assets/icons/AccountIcon'
import { getAuth, signOut } from 'firebase/auth'
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import {
  BugAntIcon,
  ChevronDownIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import NotificationsDropDown from './NotificationsDropDown'
import { useContext, useEffect, useState } from 'react'
import { CurrentUserContext } from '../contexts'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'
import { changeNotification } from '../types/changeNotification'
import makeRandomID from '../functions/makeRandomID'

export default function ActionGroup() {
  const iconContainer = 'px-1 py-0 w-fit'
  const currentAccount = useContext(CurrentUserContext)
  function handleLogout() {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        console.log('successfully logged out!')
        window.location.reload()
      })
      .catch((error) => {
        console.log('Logout failed: ' + error.Text)
      })
  }

  const [notifications, setNotifications] = useState<changeNotification[]>()
  async function getNotifications(account_id) {
    const notify_collection = collection(
      db,
      'accounts/' + account_id + '/notifications'
    )
    const unsubscribe = onSnapshot(notify_collection, (querySnapshot) => {
      var notify_temp: changeNotification[] = []
      querySnapshot.forEach((doc) => {
        var notification: changeNotification = {
          exact_time_of_change: new Date(),
          receiver: '',
          author_id: '',
          field_name: '',
          new_value: '',
          old_value: '',
          subject_id: '',
          subject_type: '',
          timestamp: '',
          my_account: false,
          my_assignment: false,
          my_client_info: false,
          my_client_role: false,
          my_client_contact: false,
          my_client_additional_info: false,
          my_client_upc_list: false,
        }
        const data = doc.data()
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            notification[keys[i]] = data[keys[i]]
          }
          notify_temp.push(notification)
        }
      })
      setNotifications(notify_temp)
      refreshNotifications()
    })
  }
  function refreshNotifications() {
    setNotificationsKey(makeRandomID(5))
  }
  const [notificationsKey, setNotificationsKey] = useState("random_string")
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad && currentAccount && currentAccount.id) {
      getNotifications(currentAccount.id)
      setFirstLoad(false)
    }
  })
  return (
    <div className=" pt-3 flex flex-row w-fit">
      <div className={iconContainer}>
        <Menu>
          <MenuHandler>
            <IconButton placeholder={'.'} variant="text">
              <ChevronDownIcon className="w-6 h-6" />
            </IconButton>
          </MenuHandler>
          <MenuList placeholder={'.'} className="flex flex-row">
            {/* FAQ is a work in progress.
            <Link to="/faq">
              <MenuItem placeholder={'.'} className="w-fit">
                <Tooltip content="Go to FAQ" placement="bottom">
                  <QuestionMarkCircleIcon className="w-6 h-6" />
                </Tooltip>
              </MenuItem>
            </Link>
            */}
            <Link to="/feedback">
              <MenuItem placeholder={'.'} className="w-fit">
                <Tooltip content="Submit Feedback" placement="bottom">
                  <PencilSquareIcon className="w-6 h-6" />
                </Tooltip>
              </MenuItem>
            </Link>
            <Link to="/report-issue">
              <MenuItem placeholder={'.'} className="w-fit">
                <Tooltip content="Report Bug" placement="bottom">
                  <BugAntIcon className="w-6 h-6" />
                </Tooltip>
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </div>
      <div className={iconContainer}>
        {notifications && (
          <NotificationsDropDown key={notificationsKey} notifications={notifications} />
        )}
      </div>
      <div className={iconContainer}>
        <Menu>
          <MenuHandler>
            <IconButton variant="text" placeholder={'.'}>
              <AccountIcon />
            </IconButton>
          </MenuHandler>
          <MenuList placeholder={undefined}>
            <Link to="/view/user">
              <MenuItem placeholder={undefined}>View Profile</MenuItem>
            </Link>
            <Link to="/">
              <MenuItem placeholder={'.'} onClick={handleLogout}>
                Sign Out
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </div>
    </div>
  )
}
