import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Radio,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase'
import { AccountsContext } from '../../../contexts'
import { account } from '../../../types/account'
import Loading from '../../../components/Loading'
import createAccount from '../../../functions/createAccount'
import ViewAccounts from './ViewAccounts'

export default function ManageAccounts() {
  const accounts = useContext(AccountsContext)
  const [permissions_presets, setPermissions] = useState({})
  const [allPermissions, setAllPermissions] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [selectedPerRole, setSelectedPerRole] = useState('')
  const [query, setQuery] = useState('')
  const [choosingPermissions, setChoosingPermissions] = useState(false)
  const [acctKey, setAcctKey] = useState('')
  const [usersWithPerm, setUsersWithPermissions] = useState<account[]>([])
  const [accountSearchOpen, setAccountSearchOpen] = useState(false)
  const [addAccount, setAddAccount] = useState(false)
  const [accountQuery, setAccountQuery] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [usersWithoutPermissions, setUsersWithoutPermissions] = useState<
    account[]
  >([])

  const TABLE_HEAD = ['Name', 'Phone #', 'Email', 'Title', '']
  const role_titles = [
    'Organizer',
    'VP',
    'Director',
    'Business Manager',
    'Marketing',
    'Admin',
    'Default',
  ]

  async function getAccountsBasedOnPermissions() {
    var accounts_from_permissions: account[] = []
    var ids_from_permissions: string[] = []
    const user_conversions = await getDocs(collection(db, 'users'))
    user_conversions.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        if (data.id && typeof data.id === 'string') {
          accounts.forEach((account) => {
            if (account.id === data.id) {
              accounts_from_permissions.push(account)
            }
          })
          ids_from_permissions.push(data.id)
        }
      }
    })
    setUsersWithPermissions(accounts_from_permissions)
    var accounts_without_permissions: account[] = []
    var ids_without_permissions: string[] = []
    accounts.forEach((account) => {
      if (ids_from_permissions.includes(account.id) === false) {
        ids_without_permissions.push(account.id)
        accounts_without_permissions.push(account)
      }
    })
    setUsersWithoutPermissions(accounts_without_permissions)
  }
  async function getPermissions() {
    await getDoc(doc(db, 'settings', 'permissions_file_templates')).then(
      (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          setPermissions(data)
        } else {
          alert(
            'There was an error loading permissions. Check internet contection and try again.'
          )
        }
      }
    )
  }
  async function getRoleAssignments() {
    await getDocs(collection(db, 'permissions')).then((querySnapshot) => {
      var temp = {}
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        if (data !== undefined) {
          temp[doc.id] = data
        }
      })
      setAllPermissions(temp)
    })
  }
  function exitWithoutSaving() {
    setSelectedAccounts([])
    setSelectedPerRole('')
    setChoosingPermissions(false)
    handleDialog()
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }
  const reloadAccountsTable = () => {
    setAcctKey(String(Math.round(Math.random() * 100000)))
  }
  function toggleAccountSelection(id: string) {
    var new_selectedAccounts_object: string[] = selectedAccounts
    if (!selectedAccounts.includes(id)) {
      new_selectedAccounts_object.push(id)
      setSelectedAccounts(new_selectedAccounts_object)
      reloadAccountsTable()
    } else {
      var current_accounts: string[] = selectedAccounts
      var final_accounts: string[] = []
      for (let index in current_accounts) {
        if (current_accounts[index] !== id) {
          final_accounts.push(current_accounts[index])
        }
      }
      new_selectedAccounts_object = final_accounts
      setSelectedAccounts(new_selectedAccounts_object)
    }
  }
  function search() {
    var array_of_accounts: account[] = []
    for (let key in accounts) {
      if (accounts[key].UID !== undefined) {
        array_of_accounts.push(accounts[key])
      }
    }
    return array_of_accounts.filter((account) =>
      account.name.toString().toLowerCase().includes(query.toLowerCase())
    )
  }
  async function saveAccountsForNewPermissions() {
    for (let i in selectedAccounts) {
      try {
        var UID
        const permissions_title = selectedPerRole
        var permissions_content = permissions_presets[selectedPerRole]
        permissions_content['title'] = permissions_title
        for (let j in accounts) {
          if (accounts[j].id === selectedAccounts[i]) {
            UID = accounts[j].UID
            console.log('Account matched with UID')
            await setDoc(doc(db, 'permissions', UID), permissions_content).then(
              () => {
                setFirstLoad(true)
              }
            )
          }
        }
      } catch (err) {
        console.log(err)
        alert(
          'Error saving new permissions for Account ID: ' +
            selectedAccounts[i] +
            '\nProceeding with next account(s).'
        )
      }
    }
    exitWithoutSaving()
  }
  async function addSelectedAccountToPermissions(account_id) {
    if (account_id !== undefined && account_id !== '') {
      await getDoc(doc(db, 'accounts', account_id)).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          if (data.UID !== undefined && data.UID !== '') {
            getDoc(doc(db, 'permissions', data.UID)).then((docSnap2) => {
              if (docSnap2.exists()) {
                alert(
                  'Account already has permissions file. \nPlease add them using the Plus Icon.'
                )
              } else {
                setDoc(doc(db, 'permissions', data.UID), {
                  title: 'Default',
                })
                  .then(() => {
                    alert('Adding Permissions was successful!')
                  })
                  .catch((err) => {
                    alert(
                      'There was an error saving the new permissions doc, please try again!'
                    )
                  })
              }
            })
          } else {
            const add_account_cred = window.confirm(
              'Are you sure you would like to give this user access?'
            )
            if (add_account_cred) {
              createAccount({ email: data.email })
                .catch((err) => {
                  console.log(err)
                  alert('Adding User Failed. Console logged the issue.')
                })
            }
          }
        } else {
          alert(
            'Could not find the selected account. Check your internet connection.'
          )
        }
      })
    } else {
      alert(
        'An Error Occurred when adding account. Please make sure you selected an option and try again.'
      )
    }
    setAccountQuery('')
    setAccountSearchOpen(false)
    setAddAccount(false)
  }
  function handleAccountSearch() {
    setAccountSearchOpen(!accountSearchOpen)
  }
  function searchAccount(accounts: account[]) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(accountQuery.toLowerCase())
    })
  }
  function permissionsDisplayFilter(permission_docs_ids: string[]) {
    var permission_levels_by_UID = {
      Organizer: [],
      VP: [],
      Director: [],
      'Business Manager': [],
      Marketing: [],
      Admin: [],
      Default: [],
    }
    var uids_without_title: string[] = []
    for (let i = 0; i < permission_docs_ids.length; i++) {
      try {
        permission_levels_by_UID[
          allPermissions[permission_docs_ids[i]].title
        ].push(permission_docs_ids[i])
      } catch (err) {
        uids_without_title.push(permission_docs_ids[i])
      }
    }

    var final_array: string[] = []
    final_array.push(...permission_levels_by_UID['Organizer'])
    final_array.push(...permission_levels_by_UID['VP'])
    final_array.push(...permission_levels_by_UID['Director'])
    final_array.push(...permission_levels_by_UID['Business Manager'])
    final_array.push(...permission_levels_by_UID['Marketing'])
    final_array.push(...permission_levels_by_UID['Admin'])
    final_array.push(...permission_levels_by_UID['Default'])

    return final_array
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad) {
      getPermissions()
      getRoleAssignments()
      getAccountsBasedOnPermissions()
      setFirstLoad(false)
    }
  })
  return (
    <div>
      <ViewAccounts />
    </div>
  )
}
