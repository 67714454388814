// React
import { Link, Navigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

// firebase
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { setDoc, doc, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore'
import { db, storage } from '../../firebase'

// components / css
import '../../App.css'
import Loading from '../../components/Loading'
import {
  Button,
  Card,
  Typography,
  Input,
  Checkbox,
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Chip,
  Menu,
  MenuList,
  MenuHandler,
  MenuItem,
} from '@material-tailwind/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import parseChange from '../../functions/parseChange'
import {
  CurrentUserContext,
  PermissionsContext,
  ReloadDataContext,
} from '../../contexts'
import { client } from '../../types/client'
import ensureClientType from '../../functions/ensureType/ensureClientType'
import clientTemplate from '../../functions/ensureType/templateClient'

export default function EditClient() {
  // general page
  const current_account = useContext(CurrentUserContext)
  const permissions = useContext(PermissionsContext)
  const [primaryKey, setKey] = useState('starting_key')
  const [firstLoad, setFirstLoad] = useState(true)
  const [redirect, setRedirect] = useState({
    path: '',
    sendIt: false,
  })
  const reload = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }

  // form contents
  const [clientInfo, setClientInfo] = useState<client>(clientTemplate())
  const [archiveClientInfo, setArchiveClientInfo] =
    useState<client>(clientTemplate())
  const [aliases, setAliases] = useState<string[]>([])
  const [aliasInput, setAliasInput] = useState<string>('')
  const [brands, setBrands] = useState<string[]>([])
  const [brandInput, setBrandInput] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [potential, setPotential] = useState<boolean>(false)
  const [defaultPotential, setDefaultPotential] = useState<boolean>(false)
  async function setCurrentClientDetails() {
    const id = window.location.href.split('#').pop()
    const client_ref = doc(db, 'clients', String(id))
    const unsub = onSnapshot(client_ref, (querySnapshot) => {
      if (querySnapshot.exists()) {
        const client = ensureClientType(querySnapshot.data())
        setClientInfo(client)
        setArchiveClientInfo(client)
        setClientLogo(client?.logo)
        setPotential(client?.potential_client)
        setDefaultPotential(client?.potential_client)
        setAliases(client?.aliases)
        setBrands(client?.brands)

        if (client.start_date !== undefined && client.start_date.length > 1) {
          const s_array = client.start_date.split('/')
          const start = new Date(
            Number(s_array[2]),
            Number(s_array[0]) - 1,
            Number(s_array[1])
          )
            .toISOString()
            .split('T')[0]
          setStartDate(start)
        }
        if (client.end_date !== undefined && client.end_date.length > 1) {
          const e_array = client.end_date.split('/')
          const end = new Date(
            Number(e_array[2]),
            Number(e_array[0]) - 1,
            Number(e_array[1])
          )
            .toISOString()
            .split('T')[0]
          setEndDate(end)
        }
      } else {
        // client not found.
      }
    })
  }
  function handleChangeStartDate(new_value) {
    // convert YYYY-MM-DD from input to MM/DD/YYYY
    setStartDate(new_value.target.value)
    var string_date = new_value.target.value
    string_date.toString()
    const date_array = string_date.split('-')
    if (string_date.length > 1) {
      string_date = date_array[1] + '/' + date_array[2] + '/' + date_array[0]
    } else {
      string_date = ''
    }
    setClientInfo({
      ...clientInfo,
      start_date: string_date,
    })
  }
  function handleChangeEndDate(new_value) {
    // convert YYYY-MM-DD from input to MM/DD/YYYY
    setEndDate(new_value.target.value)
    var string_date = new_value.target.value
    string_date.toString()
    const date_array = string_date.split('-')
    if (string_date.length > 1) {
      string_date = date_array[1] + '/' + date_array[2] + '/' + date_array[0]
    } else {
      string_date = ''
    }
    setClientInfo({
      ...clientInfo,
      end_date: string_date,
    })
  }
  function togglePotential() {
    setPotential(!potential)
  }
  function handleChangeName(new_value) {
    setClientInfo({
      ...clientInfo,
      name: new_value.target.value,
    })
  }
  function handleChangeId(new_value) {
    setClientInfo({
      ...clientInfo,
      file_id: new_value.target.value,
    })
  }
  function handleChangeComFile(new_value) {
    setClientInfo({
      ...clientInfo,
      commission_file: new_value.target.value,
    })
  }
  function handleChangeTier(new_value) {
    setClientInfo({
      ...clientInfo,
      tier: new_value,
    })
  }
  function handleChangeGln(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        gln: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        gln: '',
      })
    }
  }
  function handleChangeKnum(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        k_num: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        k_num: '',
      })
    }
  }
  function handleChangeDuns(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        duns: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        duns: '',
      })
    }
  }
  function handleChangeNumMeetings(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        num_meetings: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        num_meetings: '',
      })
    }
  }
  function handleChangeNumCommodities(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        num_comms: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        num_comms: '',
      })
    }
  }

  function handleChangeAlias(new_value) {
    setAliasInput(new_value.target.value)
  }
  function handleAddAlias() {
    var new_aliases = aliases
    new_aliases.push(String(aliasInput))
    setAliases(new_aliases)
    setClientInfo({
      ...clientInfo,
      aliases: new_aliases,
    })
    setAliasInput('')
  }
  function clearAliases() {
    setAliases([])
    setClientInfo({
      ...clientInfo,
      aliases: [],
    })
  }
  function handleChangeBrand(new_value) {
    setBrandInput(new_value.target.value)
  }
  function handleAddBrand() {
    var new_brands = brands
    new_brands.push(String(brandInput))
    setBrands(new_brands)
    setBrandInput('')
    setClientInfo({
      ...clientInfo,
      brands: new_brands,
    })
  }
  function clearBrands() {
    setBrands([])
    setClientInfo({
      ...clientInfo,
      brands: [],
    })
  }
  const [navigateHome, setNavigateHome] = useState(false)
  async function archiveClient() {
    const question =
      'Are you sure you want to archive client #' + String(clientInfo.id) + ' ?'
    if (window.confirm(question) === true) {
      var client_doc
      var info_doc
      var collected_doc = true
      var collected_info = true

      // setup batch
      await getDoc(doc(db, 'clients', clientInfo.id)).then((doc) => {
        if (doc.exists()) {
          client_doc = doc.data()
        } else {
          collected_doc = false
        }
      })
      await getDoc(doc(db, 'additional_client_info', clientInfo.id)).then(
        (doc) => {
          if (doc.exists()) {
            info_doc = doc.data()
          } else {
            collected_info = false
          }
        }
      )
      if (collected_doc) {
        setNavigateHome(true)
        await setDoc(doc(db, 'archive_clients', clientInfo.id), client_doc)
        await deleteDoc(doc(db, 'clients', clientInfo.id))
        if (collected_info) {
          await setDoc(
            doc(db, 'archive_additional_client_info', clientInfo.id),
            info_doc
          )
          await deleteDoc(doc(db, 'additional_client_info', clientInfo.id))
        }
        alert('Client Succesfully archived.')
      } else {
        alert(
          'Archiving Cleint Failed, please check your network connectivity and try again.'
        )
        redirectToClient(clientInfo.id)
      }
    } else {
      alert('Archive Cancelled.')
    }
  }

  // client logo
  const [fileIsImg, setFileIsImg] = useState(false) // bool based on if logo is png or jpeg.
  const [selectedFile, setSelectedFile] = useState<Blob>() // uploaded photo variable. (undefined until chosen.)
  const [cleintLogo, setClientLogo] = useState('') // string link to selected file after its uploaded to gstorage.
  const [fileName, setFileName] = useState<string>('')
  const onFileChange = (event) => {
    // changing file selection (not uploading to gstorage yet).
    try {
      setSelectedFile(event.target.files[0])
      setFileName(event.target.files[0].name)
      if (
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/svg+xml' ||
        event.target.files[0].type === 'image/webp' ||
        event.target.files[0].type === 'image/avif'
      ) {
        setFileIsImg(true)
      } else {
        setFileIsImg(false)
        alert('The file seems to be the wrong format. Please try again.')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onFileUpload = () => {
    if (selectedFile) {
      // uploading selected file to gstorage
      const formData = new FormData()
      formData.append('File', selectedFile)
      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: selectedFile.type,
      }

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, 'images/' + fileName)
      const uploadTask = uploadBytesResumable(
        storageRef,
        selectedFile,
        metadata
      )

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        (error) => {
          // A full list of error codes is available at
          // [Handle errors for Cloud Storage on Web | Cloud Storage for Firebase](https://firebase.google.com/docs/storage/web/handle-errors)
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)
            setClientLogo(downloadURL)
          })
        }
      )
    }
  }

  // tiers
  const [tierOptions, setTierOptions] = useState([])
  async function getTiers() {
    const docSnap = await getDoc(doc(db, 'settings', 'tiers'))
    if (docSnap.exists()) {
      var data = docSnap.data()
      setTierOptions(data.options)
    }
  }

  // submission process
  const [id, setID] = useState('') // set in SaveClient>getId and is needed for post save redirect.
  const [submiting, setSubmitting] = useState(false) // this is the modal controller
  const [submissionComplete, setSubComplete] = useState(false) // controls text and loading icon in modal
  function beginSubmissionProcess() {
    setSubmitting(true)
    saveClient()
    setTimeout(() => {
      setSubComplete(true)
    }, 2000) // this is delayed for user experience and to give time for the getID function to iterate as needed.
  }

  function redirectToClient(id) {
    setRedirect({
      path: '/view/client#' + id,
      sendIt: true,
    })
  }
  async function saveClient() {
    setSubmitting(true)
    var data = clientInfo
    data.logo = cleintLogo
    data.id = clientInfo.id
    data.potential_client = potential
    try {
      setID(data.id)
      redirectToClient(data.id)
      await parseChange(
        'client',
        data.id,
        archiveClientInfo,
        data,
        current_account
      )
      await setDoc(doc(db, 'clients', data.id), data)
    } catch (err) {
      alert(
        'There was an error saving the document. You may not have permissions to edit this page.'
      )
      closeModal()
      setFirstLoad(true)
    }
  }
  function closeModal() {
    setSubmitting(false)
    reload()
  }
  const [clientInfoReady, setClientInfoReady] = useState(false)
  const reloadData = useContext(ReloadDataContext)
  // eslint-disable-next-line
  useEffect(() => {
    if (canUserAccessAllFields === undefined && permissions.title !== '') {
      console.log(permissions)
      setCanUserAccessAllFields(!permissions.edit_all_clients)
    }
    if (clientInfoReady === false) {
      setCurrentClientDetails()
      setClientInfoReady(true)
    }
    if (firstLoad && reloadData) {
      getTiers()
      setFirstLoad(false)
    }
  })

  const [canUserAccessAllFields, setCanUserAccessAllFields] = useState<
    undefined | boolean
  >()
  return (
    <Card
      placeholder="."
      key={primaryKey}
      className="flex h-fit flex-row rounded-lg p-2 pb-10"
    >
      {redirect.sendIt ? <Navigate to={redirect.path} replace /> : <></>}
      {navigateHome && <Navigate to="/" replace />}
      {clientInfo !== undefined && canUserAccessAllFields !== undefined ? (
          <div className="flex h-fit w-full flex-row">
            <Dialog
              placeholder="."
              open={submiting}
              handler={setSubmitting}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
              }}
            >
              <DialogHeader placeholder=".">
                Setting New Client Details...
              </DialogHeader>
              <DialogBody placeholder=".">
                {submissionComplete ? (
                  <>Submission was successful!</>
                ) : (
                  <>
                    Saving changes... <Loading />
                  </>
                )}
              </DialogBody>
              <DialogFooter placeholder=".">
                {submissionComplete ? (
                  <div>
                    <Button placeholder="." variant="text" onClick={closeModal}>
                      <span>Return to Edit Client Page</span>
                    </Button>
                    <Link to={'/view/client#' + id}>
                      <Button
                        placeholder="."
                        variant="gradient"
                        color="green"
                        onClick={redirectToClient}
                      >
                        <span>View Client</span>
                      </Button>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </DialogFooter>
            </Dialog>
            <div className="h-fit w-1/2 p-2">
              <Typography placeholder="." variant="h4">
                General Information
              </Typography>
              <div className="w-1/2">
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Name"
                    type="text"
                    size="lg"
                    disabled={canUserAccessAllFields}
                    className=""
                    value={clientInfo.name}
                    onChange={handleChangeName}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="ID"
                    type="text"
                    size="lg"
                    className=""
                    disabled={canUserAccessAllFields}
                    value={clientInfo.file_id}
                    onChange={handleChangeId}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Commission File"
                    disabled={canUserAccessAllFields}
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.commission_file}
                    onChange={handleChangeComFile}
                  />
                </div>
                {defaultPotential ? (
                  <div className="p-4">
                    <Checkbox
                      crossOrigin={false}
                      onClick={togglePotential}
                      label="Potential Client"
                      disabled={canUserAccessAllFields}
                      defaultChecked
                    />
                  </div>
                ) : (
                  <div className="p-4">
                    <Checkbox
                      crossOrigin={false}
                      onClick={togglePotential}
                      disabled={canUserAccessAllFields}
                      label="Potential Client"
                    />
                  </div>
                )}
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Start Date"
                    type="date"
                    size="lg"
                    disabled={canUserAccessAllFields}
                    className=""
                    value={startDate}
                    onChange={handleChangeStartDate}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="End Date"
                    type="date"
                    size="lg"
                    disabled={canUserAccessAllFields}
                    className=""
                    value={endDate}
                    onChange={handleChangeEndDate}
                  />
                </div>
                <div className="flex flex-col p-4 pt-8">
                  <div className="flex h-fit w-fit flex-row">
                    <Typography
                      placeholder="."
                      variant="h5"
                      className=" text-md"
                    >
                      Aliases
                    </Typography>
                    {aliases.length > 0 && (
                      <>
                        <div className="flex-fit px-4">
                          <Button
                            placeholder="."
                            variant="text"
                            color="red"
                            className="p-1 px-2"
                            onClick={clearAliases}
                          >
                            Clear
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <hr className=" border-1 border-black"></hr>
                  <div className="flex flex-row flex-wrap">
                    {aliases[0] !== undefined ? (
                      <>
                        {aliases?.map((value, index) => {
                          return (
                            <div className="p-2">
                              <Chip
                                key={'alias_' + index}
                                variant="outlined"
                                value={value}
                                className="w-fit"
                              />
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex h-10 flex-row justify-between py-2 pt-4 align-bottom">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="New Alias"
                      type="text"
                      size="lg"
                      value={aliasInput}
                      onChange={handleChangeAlias}
                    ></Input>
                    <div className="h-full w-full pt-4 align-bottom">
                      <IconButton
                        placeholder="."
                        variant="text"
                        className="h-8 w-8 min-w-max"
                        onClick={handleAddAlias}
                      >
                        <PlusIcon className="h-8 w-8" />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-4">
                  <div className="flex h-fit w-fit flex-row pt-4">
                    <Typography
                      placeholder="."
                      variant="h5"
                      className=" text-md"
                    >
                      Brands
                    </Typography>
                    {brands.length > 0 && (
                      <>
                        <div className="flex-fit px-4">
                          <Button
                            placeholder="."
                            variant="text"
                            color="red"
                            className="p-1 px-2"
                            onClick={clearBrands}
                          >
                            Clear
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <hr className=" border-1 border-black "></hr>
                  <div className="flex flex-row flex-wrap">
                    {brands[0] !== undefined ? (
                      <>
                        {brands?.map((value, index) => {
                          return (
                            <div className="p-2">
                              <Chip
                                key={'brand_' + index}
                                variant="outlined"
                                value={value}
                                className="w-fit"
                              />
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex h-10 flex-row justify-between py-2 pt-4 align-bottom">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="New Brand"
                      type="text"
                      size="lg"
                      value={brandInput}
                      onChange={handleChangeBrand}
                    ></Input>
                    <div className="h-full w-full pt-4 align-bottom">
                      <IconButton
                        placeholder="."
                        variant="text"
                        className="h-8 w-8 min-w-max"
                        onClick={handleAddBrand}
                      >
                        <PlusIcon className="h-8 w-8" />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-4 pt-8"></div>
              </div>
              <div className="flex flex-row p-10">
                <div>
                  <Button placeholder="." onClick={beginSubmissionProcess}>
                    Save Changes
                  </Button>
                </div>
                {permissions.edit_all_clients && (
                  <div className="px-5">
                    <Button
                      placeholder={'.'}
                      variant="outlined"
                      disabled={canUserAccessAllFields}
                      color="red"
                      onClick={archiveClient}
                    >
                      Archive Client
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="h-fit w-1/2 p-2">
              <Typography placeholder="." variant="h4">
                Logo
              </Typography>
              <div className="h-full w-full justify-center align-middle">
                {cleintLogo !== '' ? (
                  <>
                    <img
                      className="max-w-80 max-h-40 overflow-visible"
                      src={cleintLogo}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <Avatar
                      placeholder="."
                      className="h-40 w-40 overflow-visible border-4"
                      src=""
                    />
                  </>
                )}
                <div className="w-1/2 py-6">
                  <Input
                    crossOrigin={false}
                    type="file"
                    onChange={onFileChange}
                    label="Choose File"
                  />
                  <div className="py-6">
                    {fileIsImg ? (
                      <>
                        <Button
                          placeholder="."
                          onClick={() => {
                            onFileUpload()
                          }}
                        >
                          Set as New Logo
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {cleintLogo !== '' ? (
                      <>
                        <Button
                          placeholder="."
                          variant="text"
                          onClick={() => {
                            setClientLogo('')
                          }}
                        >
                          Remove Image
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="p-4">
                  <div className="flex h-fit w-fit flex-row pt-4">
                    <Typography
                      placeholder="."
                      variant="h5"
                      className=" text-md"
                    >
                      Tier
                    </Typography>
                  </div>
                  <hr className=" border-1 border-black "></hr>
                  <div className="p-2">
                    <Menu>
                      <MenuHandler>
                        <Button
                          placeholder="."
                          variant="text"
                          disabled={canUserAccessAllFields}
                        >
                          {clientInfo.tier !== '' ? (
                            <>{clientInfo.tier}</>
                          ) : (
                            <>Choose Tier</>
                          )}
                        </Button>
                      </MenuHandler>
                      <MenuList placeholder=".">
                        {tierOptions.map((option, index) => {
                          return (
                            <MenuItem
                              placeholder="."
                              key={index + '_tier_option'}
                              onClick={() => {
                                handleChangeTier(option)
                              }}
                            >
                              {option}
                            </MenuItem>
                          )
                        })}
                      </MenuList>
                    </Menu>
                  </div>
                  <div className="p-4">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="GLN"
                      type="text"
                      size="lg"
                      className=""
                      value={clientInfo.gln}
                      onChange={handleChangeGln}
                    />
                  </div>
                  <div className="p-4">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="K Number"
                      type="text"
                      size="lg"
                      className=""
                      value={clientInfo.k_num}
                      onChange={handleChangeKnum}
                    />
                  </div>
                  <div className="p-4">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="Duns"
                      type="text"
                      size="lg"
                      className=""
                      value={clientInfo.duns}
                      onChange={handleChangeDuns}
                    />
                  </div>
                  <div className="p-4">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="Seasonal / Sales Meetings"
                      type="text"
                      size="lg"
                      className=""
                      value={clientInfo.num_meetings}
                      onChange={handleChangeNumMeetings}
                    />
                  </div>
                  <div className="p-4">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="Number of Commodities in Kroger"
                      type="text"
                      size="lg"
                      className=""
                      value={clientInfo.num_comms}
                      onChange={handleChangeNumCommodities}
                    />
                  </div>
                </div>
                {/* Child field is being shadow cut because it was a terrible idea and not worth building. It is not being deleted incase certain people insist on it coming back. (Ideally nobody notices.)
                <div className="p-4">
                  <div className="flex h-fit w-full flex-row justify-between pt-4">
                    <Typography
                      placeholder="."
                      variant="h5"
                      className=" text-md"
                    >
                      Child
                    </Typography>
                    {childSelection !== "" && (
                      <Button
                        placeholder="."
                        className="p-1"
                        variant="text"
                        onClick={clearChildSelection}
                      >
                        clear selection
                      </Button>
                    )}
                  </div>
                  <hr className=" border-1 border-black "></hr>
                  {childSelection !== "" && (
                    <Typography
                      placeholder="."
                      variant="small"
                      className="text-md p-2 font-bold text-black"
                    >
                      {childSelection}
                    </Typography>
                  )}
                  <div className="p-2">
                    <div className="w-96 py-2">
                      <Input
                        crossOrigin={false}
                        label="Search"
                        value={childQuery}
                        onChange={(e) => {
                          setChildQuery(e.target.value);
                        }}
                        onSelect={handleOpenChildSearch}
                        icon={<MagnifyingGlassIcon />}
                      />
                    </div>
                    <div className="w-96 py-2">
                      {childSearchOpen && (
                        <ul className="flex h-48 w-full flex-col overflow-auto rounded-md border-2">
                          {firstLoad === false ? (
                            <>
                              {searchChild(clients).map((client, index) => {
                                if (client?.id !== clientInfo.id) {
                                  return (
                                    <li
                                      className="px-2 py-1"
                                      key={index + "_cleintButton"}
                                    >
                                      <Button
                                        placeholder="."
                                        variant="text"
                                        className="rounded-none p-1"
                                        onClick={() => {
                                          selectChildClient(client);
                                        }}
                                      >
                                        {client?.name}
                                      </Button>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <div
                                      key={index + "_clientButtonEmpty"}
                                    ></div>
                                  );
                                }
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        ) : (
          <div className='p-4'><Loading/></div>
        )}

    </Card>
  )
}
