import {
  ChevronLeftIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import {
  Button,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import { account } from '../types/account'
import { AccountsContext, ClientsContext } from '../contexts'
import Loading from './Loading'
import { client } from '../types/client'
import AccountName from './AccountName'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { Link } from 'react-router-dom'

export default function FindAndReplace({ backArrow}) {
  const accounts = useContext(AccountsContext)
  const clients = useContext(ClientsContext)
  const [roleTitles, setRoleTitles] = useState<string[]>([])

  const [findQuery, setFindQuery] = useState<string>('')
  const [replaceQuery, setReplaceQuery] = useState<string>('')

  const [findAcct, setFindAcct] = useState<account | undefined>()
  const [replaceAcct, setReplaceAcct] = useState<account | undefined>()

  const [submitting, setSubmitting] = useState<boolean | undefined>()
  const [successfulSubmission, setSuccessfulSubmission] = useState<
    boolean | undefined
  >()

  const [loadingQueryResults, setLoadingQueryResults] = useState<
    boolean | undefined
  >()

  function findAcctSearch(accounts) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(findQuery.toLowerCase())
    })
  }
  function replaceAcctSearch(accounts) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(replaceQuery.toLowerCase())
    })
  }

  const [clientsWithFind, setClientsWithFind] = useState<client[] | undefined>()
  const [clientsAfterReplace, setClientsAfterReplace] = useState<
    client[] | undefined
  >()

  function loadQueryResults() {
    var clients_with_find: client[] = []
    var clients_after_replace: client[] = []
    if (findAcct && replaceAcct) {
      clients.forEach((client: client) => {
        const roles = client.roles
        Object.keys(roles).forEach((key) => {
          if (roles[key].includes(findAcct.id)) {
            if (!clients_with_find.includes(client)) {
              clients_with_find.push(client)
            }
          }
        })
      })
      clients_with_find = JSON.parse(JSON.stringify(clients_with_find))
      clients_with_find.forEach((client) => {
        var client_copy = JSON.parse(JSON.stringify(client))
        Object.keys(client_copy.roles).forEach((key) => {
          const account_array = client_copy.roles[key]
          if (account_array.includes(findAcct.id)) {
            client_copy.roles[key] = client_copy.roles[key].filter(
              (account_id) => account_id !== findAcct.id
            )

            if (!account_array.includes(replaceAcct)) {
              client_copy.roles[key].push(replaceAcct.id)
            }
          }
        })
        clients_after_replace.push(JSON.parse(JSON.stringify(client_copy)))
      })
    }
    setClientsAfterReplace(clients_after_replace)
    setClientsWithFind(clients_with_find)
  }

  async function getRoles() {
    await getDoc(doc(db, 'settings', 'roles')).then((doc) => {
      if (doc.exists()) {
        setRoleTitles(doc.data().options)
      }
    })
  }

  function handleSubmit() {
    const confirm_request = `are you sure you sure that you want to implement this change? It will affect ${clientsAfterReplace?.length} cleints.`
    const response = window.confirm(confirm_request)
    if (response === true && clientsAfterReplace) {
      clientsAfterReplace.forEach((clientDoc: client) => {
        setDoc(doc(db, 'clients', clientDoc.id), clientDoc)
      })
      setSubmitting(false)
      setSuccessfulSubmission(true)
    } else {
      setSubmitting(false)
      setSuccessfulSubmission(false)
    }
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getRoles()
      setFirstLoad(false)
    }
  })

  return (
    <div className="w-full h-full">
      <div className="flex justify-between w-full">
        <IconButton
          placeholder={'.'}
          variant="text"
          className=""
          onClick={backArrow}
        >
          <ChevronLeftIcon className="w-10 h-10" />
        </IconButton>
        <div className=" text-xl font-black text-black px-4 py-2">
          Quick Edit
        </div>
        <div className="w-14"></div>
      </div>
      <hr />
      {submitting === undefined ? (
        <div>
          <div>
            <div className="flex flex-row p-4">
              <Typography
                placeholder={'.'}
                variant="h4"
                className="pr-4 text-black"
              >
                Find and Replace
              </Typography>
              <Tooltip
                content={
                  <div>
                    The account selected in the 'find' field will be replaced by
                    the account selected in the 'Replace With' field.
                  </div>
                }
              >
                <IconButton variant="text" placeholder={'.'}>
                  <InformationCircleIcon className="h-8 w-8" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="p-4">
              <div>
                {findAcct ? (
                  <Link to="">
                    <Typography
                      placeholder={'.'}
                      variant="h6"
                      className="p-2 "
                      onClick={() => setFindAcct(undefined)}
                      color="blue"
                    >
                      Find: {findAcct?.name}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    placeholder={'.'}
                    variant="h6"
                    className="p-2 text-black"
                    onClick={() => setFindAcct(undefined)}
                  >
                    Find:
                  </Typography>
                )}
              </div>
              {findAcct === undefined && (
                <div className="p-2">
                  <div className="w-96 py-2">
                    <Input
                      crossOrigin={false}
                      label="Search"
                      value={findQuery}
                      onChange={(e) => {
                        setFindQuery(e.target.value)
                      }}
                      icon={<MagnifyingGlassIcon />}
                    />
                  </div>
                  <div className="w-96 py-2">
                    <ul className="flex h-48 w-full flex-col overflow-auto rounded-md border-2">
                      {findAcctSearch(accounts).map(
                        (account: account, index) => {
                          if (account.internal) {
                            return (
                              <li
                                className="px-2 py-1"
                                key={index + '_cleintButton'}
                              >
                                <Button
                                  placeholder="."
                                  variant="text"
                                  className="rounded-none p-1"
                                  onClick={() => {
                                    setFindAcct(account)
                                  }}
                                >
                                  {account?.name}
                                </Button>
                              </li>
                            )
                          } else {
                            return (
                              <div key={index + '_clientButtonEmpty'}></div>
                            )
                          }
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="p-4">
              <div>
                {replaceAcct ? (
                  <Link to="">
                    <Typography
                      placeholder={'.'}
                      variant="h6"
                      className="p-2"
                      color="blue"
                      onClick={() => setReplaceAcct(undefined)}
                    >
                      Replace With: {replaceAcct?.name}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    placeholder={'.'}
                    variant="h6"
                    className="p-2 text-black"
                  >
                    Replace With:
                  </Typography>
                )}
              </div>
              {replaceAcct === undefined && (
                <div className="p-2">
                  <div className="w-96 py-2">
                    <Input
                      crossOrigin={false}
                      label="Search"
                      value={replaceQuery}
                      onChange={(e) => {
                        setReplaceQuery(e.target.value)
                      }}
                      icon={<MagnifyingGlassIcon />}
                    />
                  </div>
                  <div className="w-96 py-2">
                    <ul className="flex h-48 w-full flex-col overflow-auto rounded-md border-2">
                      {replaceAcctSearch(accounts).map(
                        (account: account, index) => {
                          if (account.internal) {
                            return (
                              <li
                                className="px-2 py-1"
                                key={index + '_cleintButton'}
                              >
                                <Button
                                  placeholder="."
                                  variant="text"
                                  className="rounded-none p-1"
                                  onClick={() => {
                                    setReplaceAcct(account)
                                  }}
                                >
                                  {account?.name}
                                </Button>
                              </li>
                            )
                          } else {
                            return (
                              <div key={index + '_clientButtonEmpty'}></div>
                            )
                          }
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="p-8">
              {findAcct && replaceAcct ? (
                <Button
                  placeholder={'.'}
                  color="blue"
                  onClick={() => {
                    setLoadingQueryResults(true)
                    loadQueryResults()
                    setLoadingQueryResults(false)
                  }}
                >
                  Preview Change
                </Button>
              ) : (
                <Button placeholder={'/'} disabled variant="outlined">
                  Preview Change
                </Button>
              )}
            </div>
          </div>
          {loadingQueryResults !== undefined && (
            <div>
              {loadingQueryResults === true ? (
                <div className="p-8">
                  <Loading />
                </div>
              ) : (
                <div>
                  {clientsWithFind && (
                    <div>
                      <Typography
                        placeholder={'.'}
                        variant="h4"
                        className="px-6 text-black"
                      >
                        Before
                      </Typography>
                      <div className="p-6">
                        <table className="min-h-full w-full table-auto text-left p-0">
                          <thead>
                            <tr>
                              <th className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none"
                                >
                                  Name
                                </Typography>
                              </th>
                              {roleTitles.map((column) => (
                                <th
                                  key={column}
                                  className=" border-b border-blue-gray-100 bg-blue-gray-50 px-2 py-1 w-28"
                                >
                                  <div className=" flex flex-row w-fit justify-between">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none"
                                    >
                                      {column}
                                    </Typography>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {clientsWithFind.map((client, index) => (
                              <tr key={index} className={' h-14'}>
                                <td
                                  key={client.id + index}
                                  className={'py-0 px-4 border text-black'}
                                >
                                  {client.name}
                                </td>
                                {roleTitles.map((title, index2) => {
                                  return (
                                    <td
                                      key={client.id + index2}
                                      className={'py-0 px-4 border'}
                                    >
                                      {client.roles[title] &&
                                        client.roles[title].map(
                                          (account_id) => (
                                            <AccountName
                                              accounts={accounts}
                                              account_id={account_id}
                                            />
                                          )
                                        )}
                                    </td>
                                  )
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {clientsAfterReplace && (
                    <div>
                      <Typography
                        placeholder={'.'}
                        variant="h4"
                        className="px-6 text-black"
                      >
                        After
                      </Typography>
                      <div className="p-6">
                        <table className="min-h-full w-full table-auto text-left p-0">
                          <thead>
                            <tr>
                              <th className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none"
                                >
                                  Name
                                </Typography>
                              </th>
                              {roleTitles.map((column) => (
                                <th
                                  key={column}
                                  className=" border-b border-blue-gray-100 bg-blue-gray-50 py-1 px-2 w-28"
                                >
                                  <div className=" flex flex-row w-fit justify-between">
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none"
                                    >
                                      {column}
                                    </Typography>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {clientsAfterReplace.map((client, index) => (
                              <tr key={index} className={' h-14'}>
                                <td
                                  key={client.id + index}
                                  className={'py-0 px-4 border text-black'}
                                >
                                  {client.name}
                                </td>
                                {roleTitles.map((title, index2) => {
                                  return (
                                    <td
                                      key={client.id + index2}
                                      className={'py-0 px-4 border'}
                                    >
                                      {client.roles[title] &&
                                        client.roles[title].map(
                                          (account_id) => (
                                            <AccountName
                                              accounts={accounts}
                                              account_id={account_id}
                                            />
                                          )
                                        )}
                                    </td>
                                  )
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="p-8">
                    <Button
                      placeholder={'.'}
                      color="green"
                      onClick={() => {
                        setSubmitting(true)
                        handleSubmit()
                      }}
                    >
                      Implement Change
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {submitting === true ? (
            <div className=" p-16">
              <Loading />
            </div>
          ) : (
            <div>
              {successfulSubmission ? (
                <div className="p-16">Successful Submission!</div>
              ) : (
                <div className="p-16">Unsuccessful Submission.</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
