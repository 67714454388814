import { MutableRefObject, useContext, useEffect, useState } from 'react'
import '../../App.css'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
  ReloadDataContext,
} from '../../contexts'
import { account } from '../../types/account'
import { client } from '../../types/client'
import AccountView from './AccountView'
import Loading from '../../components/Loading'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase'
import ensureAccountType from '../../functions/ensureType/ensureAccountType'
import AccountNotFound from './AccountNotFound'

export default function AccountPageHandler() {
  const accounts: account[] = useContext(AccountsContext)
  const currentUser: account = useContext(CurrentUserContext)
  const [account, setAccount] = useState<account>()
  const clients: client[] = useContext(ClientsContext)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [clientsWithRef, setClientsWithRef] = useState<client[]>([])
  const [clientsParsed, setClientsParsed] = useState(false)
  const [failedToFindAccount, setFailedToFindAccount] = useState(false)

  function parseClients(clients: client[], account_id: string) {
    var clients_with_ref: client[] = []
    for (let i = 0; i < clients.length; i++) {
      if (clients[i].active) {
        const contacts = clients[i].contacts
        const roles = clients[i].roles

        Object.keys(contacts).forEach((title) => {
          if (contacts[title]) {
            if (contacts[title].includes(account_id)) {
              clients_with_ref.push(clients[i])
            }
          }
        })
        Object.keys(roles).forEach((title) => {
          if (roles[title]) {
            if (roles[title].includes(account_id)) {
              clients_with_ref.push(clients[i])
            }
          }
        })
      }
    }
    setClientsParsed(true)
    setClientsWithRef(clients_with_ref)
  }

  function getAccount(id) {
    const accountDoc = doc(db, 'accounts', id)
    const unsubscribe = onSnapshot(accountDoc, (querySnapshot) => {
      if (querySnapshot.exists()) {
        setAccount(ensureAccountType(querySnapshot.data()))
      } else {
        setFailedToFindAccount(true)
      }
    })
  }
  const reloadData = useContext(ReloadDataContext)

  useEffect(() => {
    if (firstLoad && accounts && clients && reloadData) {
      reloadData()
      if (window.location.href.includes('view/user')) {
        if (currentUser) {
          const id = currentUser.id
          getAccount(id)
          parseClients(clients, id)
        }
      } else {
        const id = window.location.href.split('#').pop()
        if (id) {
          getAccount(id)
          parseClients(clients, id)
        } else {
          setFailedToFindAccount(true)
        }
      }
      setFirstLoad(false)
    }
  })
  return (
    <>
      <>
        {failedToFindAccount ? (
          <AccountNotFound />
        ) : (
          <>
            {clientsParsed && account ? (
              <AccountView
                account={account}
                clients_with_ref={clientsWithRef}
              />
            ) : (
              <Loading />
            )}
          </>
        )}
      </>
    </>
  )
}
