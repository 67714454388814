// React
import { Link, Navigate } from 'react-router-dom'
import React, { MutableRefObject, useContext, useEffect, useState } from 'react'

// firebase
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import {
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore'
import { db, storage } from '../../firebase'

// components / css
import '../../App.css'
import Loading from '../../components/Loading'
import {
  Button,
  Card,
  Typography,
  Input,
  Checkbox,
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import parseChange from '../../functions/parseChange'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
  ReloadDataContext,
} from '../../contexts'
import { account } from '../../types/account'
import { client } from '../../types/client'
import ensureAccountType from '../../functions/ensureType/ensureAccountType'

export default function EditAccount() {
  // redirect and reload
  const [primaryKey, setKey] = useState('starting_key')
  const [redirect, setRedirect] = useState({
    path: '',
    sendIt: false,
  })
  const reload = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }

  // form contents
  const [accountInfo, setAcctInfo] = useState<account>({
    name: '',
    phone: '',
    email: '',
    title: '',
    internal: true,
    company: '',
    avatar: '',
    id: '',
  })

  const [defaultInitial, setDefaultInitial] = useState<undefined | boolean>()
  const current_account = useContext(CurrentUserContext)
  const permissions = useContext(PermissionsContext)
  const clients = useContext(ClientsContext)
  const [firstLoad, setFirstLoad] = useState(true)

  function setAccount() {
    const tag = window.location.href.split('#').pop()
    const docRef = doc(db, 'accounts', String(tag))
    const unsub = onSnapshot(docRef, (querySnap) => {
      if (querySnap.exists()) {
        const account_doc = ensureAccountType(querySnap.data())
        setAcctInfo(account_doc)
        setArchiveAccountInfo(ensureAccountType(account_doc))
        setDefaultInitial(account_doc.internal)
        if (account_doc.avatar) {
          setCustomAvatar(account_doc.avatar)
        } else {
          setCustomAvatar('')
        }
      } else {
        navHome()
      }
    })
    return unsub
  }
  function handleChangeName(new_value) {
    setAcctInfo({
      ...accountInfo,
      name: new_value.target.value,
    })
  }
  function handleChangePhone(new_value) {
    setAcctInfo({
      ...accountInfo,
      phone: new_value.target.value,
    })
  }
  function handleChangeCellPhone(new_value) {
    setAcctInfo({
      ...accountInfo,
      'Cell Phone': new_value.target.value,
    })
  }
  function handleChangeEmail(new_value) {
    setAcctInfo({
      ...accountInfo,
      email: new_value.target.value,
    })
  }
  function handleChangeTitle(new_value) {
    setAcctInfo({
      ...accountInfo,
      title: new_value.target.value,
    })
  }
  function toggleInternal() {
    setAcctInfo({
      ...accountInfo,
      internal: !accountInfo.internal,
    })
  }

  // account photo
  const [fileIsImg, setFileIsImg] = useState(false) // bool based on if profile pic is png.
  const [selectedFile, setSelectedFile] = useState<Blob | undefined>() // uploaded photo variable. (undefined until chosen.)
  const [customAvatar, setCustomAvatar] = useState('') // string link to selected file after its uploaded to gstorage.
  const [fileName, setFileName] = useState<string>('')
  const onFileChange = async (event) => {
    // changing file selection (not uploading to gstorage yet).
    try {
      if (
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/JPG' ||
        event.target.files[0].type === 'image/webp'
      ) {
        // downsizing file
        const file = event.target.files[0]
        const blobURL = URL.createObjectURL(file)
        const MIME_TYPE = 'image/jpeg'
        const QUALITY = 1
        const img = new Image()
        img.src = blobURL
        img.onerror = function () {
          URL.revokeObjectURL(this.src)
          // Handle the failure properly
          console.log('Cannot load image')
        }
        img.onload = function () {
          URL.revokeObjectURL(blobURL)
          const [newWidth, newHeight] = calculateSize(img, 300, 300)
          const canvas = document.createElement('canvas')
          canvas.width = newWidth
          canvas.height = newHeight
          const ctx = canvas.getContext('2d')
          if (ctx !== null) {
            ctx.drawImage(img, 0, 0, newWidth, newHeight)
            canvas.toBlob(
              (blob) => {
                // Handle the compressed image. es. upload or save in local state
                // displayInfo('Original file', file);
                if (blob !== null) {
                  setSelectedFile(blob)
                }
              },
              MIME_TYPE,
              QUALITY
            )
          }
        }
        function calculateSize(img, maxWidth, maxHeight) {
          let width = img.width
          let height = img.height

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width)
              width = maxWidth
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height)
              height = maxHeight
            }
          }
          return [width, height]
        }
        setFileIsImg(true)
        setFileName(accountInfo.id)
      } else {
        setFileIsImg(false)
        alert('The file seems to be the wrong format. Please try again.')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onFileUpload = async () => {
    if (selectedFile) {
      // uploading selected file to gstorage
      const formData = new FormData()
      formData.append('File', selectedFile)
      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: selectedFile.type,
      }

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, 'account_profile_pictures/' + fileName)
      const uploadTask = uploadBytesResumable(
        storageRef,
        selectedFile,
        metadata
      )

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        (error) => {
          // A full list of error codes is available at
          // [Handle errors for Cloud Storage on Web | Cloud Storage for Firebase](https://firebase.google.com/docs/storage/web/handle-errors)
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)
            setCustomAvatar(downloadURL)
          })
        }
      )
    }
  }

  // submission process
  const [id, setID] = useState('') // set in SaveAccount>getId and is needed for post save redirect.
  const [submiting, setSubmitting] = useState(false) // this is the modal controller
  const [submissionComplete, setSubComplete] = useState(false) // controls text and loading icon in modal
  async function beginSubmissionProcess() {
    setSubmitting(true)
    await saveAccount().then(() => {
      setSubComplete(true)
    })
  }
  function endSubmissionProccess() {
    // return values to default
    setFileIsImg(false)
    setSubComplete(false)
    setAccount()
  }
  function redirectToAccount() {
    setRedirect({
      path: '/view/account#' + id,
      sendIt: true,
    })
  }

  const [failedSignUp, setFailSignUp] = useState(false)
  const [archiveAccountInfo, setArchiveAccountInfo] = useState<account>({
    name: '',
    phone: '',
    email: '',
    title: '',
    internal: true,
    company: '',
    avatar: '',
    id: '',
  })
  async function saveAccount() {
    try {
      var data: account = accountInfo
      if (customAvatar !== undefined) {
        data.avatar = customAvatar
      } else {
        data.avatar = ''
      }
      await parseChange(
        'account',
        data.id,
        archiveAccountInfo,
        data,
        current_account
      )
      setID(data.id)
      endSubmissionProccess()
      setFailSignUp(false)
      navToAccount()
      await setDoc(doc(db, 'accounts', String(data.id)), data)
    } catch (err) {
      setFailSignUp(true)
      console.log(err)
    }
  }
  async function archiveAccount() {
    const request =
      'Are you sure you want to archive account #' + accountInfo.id + '?'
    if (window.confirm(request) === true) {
      try {
        navHome()
        const account_doc = await getDoc(doc(db, 'accounts', accountInfo.id))
        const account_info = account_doc.data()
        await clearAccountFromClients()
        await setDoc(doc(db, 'archive_accounts', accountInfo.id), account_info)
        if (unsubRef) {
          unsubRef.current && unsubRef.current()
        }
        await deleteDoc(doc(db, 'accounts', accountInfo.id))
      } catch {
        alert(
          'An Error Occured when deleting account. Please Reload, as the account may no longer exist.'
        )
      }
    } else {
      console.log('Archival Cancelled.')
    }
  }
  async function clearAccountFromClients() {
    type role_ref = {
      client_id: string
      client: client
      role_title: string
    }
    var array_of_roles: role_ref[] = []
    clients.forEach((client) => {
      if (client.roles !== undefined) {
        Object.keys(client.roles).forEach((role_key) => {
          client.roles[role_key].forEach((account_id) => {
            if (account_id === accountInfo.id) {
              array_of_roles.push({
                client_id: client.id,
                client: client,
                role_title: role_key,
              })
            }
          })
        })
      }
    })
    for (let i = 0; i < array_of_roles.length; i++) {
      const ref = array_of_roles[i]
      console.log('Client Doc before:', ref.client)
      const client: client = JSON.parse(JSON.stringify(ref.client))
      var new_client = client
      if (client.roles[ref.role_title].length > 1) {
        new_client.roles[ref.role_title] = client.roles[ref.role_title].filter(
          (account_id) => account_id !== accountInfo.id
        )
      } else {
        new_client.roles[ref.role_title] = []
      }
      await setDoc(doc(db, 'clients', ref.client_id), client)
    }
  }
  function navHome() {
    setRedirect({
      sendIt: true,
      path: '/',
    })
  }
  function navToAccount() {
    setRedirect({
      sendIt: true,
      path: '/view/account#' + accountInfo.id,
    })
  }
  function closeModal() {
    setSubmitting(false)
    reload()
  }

  const reloadData = useContext(ReloadDataContext)
  const unsubRef: MutableRefObject<Unsubscribe> | MutableRefObject<undefined> =
    React.useRef()
  const [archiving, setArchiving] = useState(false)
  useEffect(() => {
    if (firstLoad && !archiving) {
      reloadData()
      const unsub = setAccount()
      unsubRef.current = unsub
      setFirstLoad(false)
    }
  }, [reloadData, setAccount, setFirstLoad])

  return (
    <Card
      placeholder="."
      key={primaryKey}
      className="flex h-screen flex-row rounded-sm p-10"
    >
      {redirect.sendIt ? <Navigate to={redirect.path} replace /> : <></>}
      <Dialog
        placeholder="."
        open={submiting}
        handler={setSubmitting}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader placeholder=".">
          Setting New Account Details...
        </DialogHeader>
        {failedSignUp === true ? (
          <>
            <DialogBody placeholder=".">
              {submissionComplete ? (
                <>
                  <strong>Submission was Unsuccessful. </strong>
                  <br></br> An Unknown Issue occurred. <br />
                  Please try again or contact support.
                </>
              ) : (
                <>
                  Setting New Account Details...{'  '}
                  <Loading />
                </>
              )}
            </DialogBody>
            <DialogFooter placeholder=".">
              <div>
                <Button placeholder="." variant="text" onClick={closeModal}>
                  <span>Return to Edit Account Page</span>
                </Button>
              </div>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogBody placeholder=".">
              {submissionComplete ? (
                <>Submission was successful!</>
              ) : (
                <>
                  Setting New Account Details... {'  '}
                  <Loading />
                </>
              )}
            </DialogBody>
            <DialogFooter placeholder=".">
              {submissionComplete ? (
                <div>
                  <Button placeholder="." variant="text" onClick={closeModal}>
                    <span>Return to Create Account Page</span>
                  </Button>
                  <Link to={'/view/account#' + id}>
                    <Button
                      placeholder="."
                      variant="gradient"
                      color="green"
                      onClick={redirectToAccount}
                    >
                      <span>View Account</span>
                    </Button>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </DialogFooter>
          </>
        )}
      </Dialog>
      <div className="w-1/2 p-2">
        <Typography placeholder="." variant="h4">
          General Information
        </Typography>
        <div className="w-1/2">
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Name"
              type="text"
              size="lg"
              className=""
              value={accountInfo.name}
              onChange={handleChangeName}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Work Phone"
              type="text"
              size="lg"
              className=""
              value={accountInfo.phone}
              onChange={handleChangePhone}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Cell Phone"
              type="text"
              size="lg"
              className=""
              value={accountInfo['Cell Phone']}
              onChange={handleChangeCellPhone}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Email"
              type="text"
              size="lg"
              className=""
              value={accountInfo.email}
              onChange={handleChangeEmail}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Title"
              type="text"
              size="lg"
              className=""
              value={accountInfo.title}
              onChange={handleChangeTitle}
            />
          </div>
          {permissions.edit_all_roles && (
            <div className="p-4">
              {defaultInitial !== undefined && defaultInitial === true ? (
                <Checkbox
                  crossOrigin={false}
                  defaultChecked
                  label="OSMG Employee?"
                  onClick={toggleInternal}
                ></Checkbox>
              ) : (
                <Checkbox
                  crossOrigin={false}
                  label="OSMG Employee?"
                  onClick={toggleInternal}
                ></Checkbox>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row p-10">
          <Button placeholder="." onClick={beginSubmissionProcess}>
            Submit
          </Button>
          {permissions.edit_accounts && (
            <div className="px-4">
              <Button
                placeholder={'.'}
                variant="outlined"
                color="red"
                onClick={archiveAccount}
              >
                Archive Account
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="h-full w-1/2 p-2">
        <Typography placeholder="." variant="h4">
          Profile Photo
        </Typography>
        <div className="h-full w-full justify-center align-middle">
          {customAvatar !== '' ? (
            <>
              <Avatar
                placeholder="."
                className="h-40 w-40 border-4"
                src={customAvatar}
              />
            </>
          ) : (
            <>
              <Avatar placeholder="." className="h-40 w-40 border-4" src="" />
            </>
          )}
          <div className="w-1/2 py-6">
            <Input
              crossOrigin={false}
              type="file"
              onChange={onFileChange}
              label="Choose File"
              id="profileIcon"
            />
            <div className="py-6">
              {fileIsImg ? (
                <>
                  <Button
                    placeholder="."
                    onClick={() => {
                      onFileUpload()
                    }}
                    className="p-2"
                  >
                    Set as New Profile Icon
                  </Button>
                </>
              ) : (
                <></>
              )}

              {customAvatar !== '' ? (
                <div className="py-4">
                  <Button
                    placeholder="."
                    variant="text"
                    onClick={() => {
                      setCustomAvatar('')
                    }}
                    className="p-2"
                  >
                    Remove Custom Image
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
