import { client } from '../../../../types/client'
import { permission } from '../../../../types/permission'
import { useContext, useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  IconButton,
  Typography,
} from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import { PencilIcon, UserIcon } from '@heroicons/react/24/outline'
import { AccountsContext } from '../../../../contexts'

export default function ContactsAndRoles(props: {
  clientDoc: client
  permissions: permission
  editMode: boolean
  isMyClient: Boolean
}) {
  const client = props.clientDoc
  const isMyClient = props.isMyClient
  const editMode = props.editMode
  const permissions = props.permissions
  const accounts = useContext(AccountsContext)

  type account_set = {
    [key: string]: string[]
  }
  const [roles, setRoles] = useState<account_set | undefined>()
  const [contacts, setContacts] = useState<account_set | undefined>()

  const [canEdit, setCanEdit] = useState<boolean | undefined>()
  function decideIfCanEdit() {
    if (
      props.permissions.edit_all_clients === true ||
      props.permissions.edit_all_roles ||
      props.permissions.edit_all_contacts ||
      (props.isMyClient &&
        (props.permissions.edit_assigned_clients ||
          props.permissions.edit_my_client_contacts ||
          props.permissions.edit_my_client_roles))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (canEdit === undefined) {
      setCanEdit(decideIfCanEdit())
    }
    if (roles === undefined || contacts === undefined) {
      console.log('Running evaluate Roles in Client Contacts and Roles section')
      console.log(client)
      evaluateContactsAndRoles(client)
    }
  })

  function evaluateContactsAndRoles(client) {
    const roles = client.roles
    const contacts = client.contacts
    setRoles(roles ? roles : [])
    setContacts(contacts ? contacts : [])
  }

  const hasRoles = roles !== undefined && Object.keys(roles).length > 0
  const hasContacts = contacts !== undefined && Object.keys(contacts).length > 0

  // UI Components
  function RolesDisplay({ roles }) {
    function sortByRoleTitle(a: string, b: string) {
      const titles = [
        'VP',
        'Director',
        'Business Manager',
        'Assistant Business Manager',
        'Insights',
        'Assistant Insights Manager',
        'Orders',
        'Claims',
        'Marketing',
        'Assistant',
      ]
      var a_num
      var b_num
      if (titles.includes(a)) {
        a_num = titles.indexOf(a)
      } else {
        a_num = 20
      }
      if (titles.includes(b)) {
        b_num = titles.indexOf(b)
      } else {
        b_num = 20
      }
      const result = a_num - b_num
      return result
    }

    return (
      <div className="flex h-fit w-full flex-row flex-wrap">
        {Object.keys(roles)
          .sort(sortByRoleTitle)
          .map((key) => {
            return (
              <div key={'roles_display_' + key}>
                {roles[key] && <>{rolesBlock(key, roles[key])}</>}
              </div>
            )
          })}
      </div>
    )
  }
  function rolesBlock(key, ids: string[]) {
    return (
      <div className="h-fit max-w-sm p-4" key={key + '_rolesBlock'}>
        <Typography
          placeholder="."
          variant="h5"
          color="blue-gray"
          className="px-4"
        >
          {key}
        </Typography>
        <hr className=" w-full border-2 border-blue-700" />
        <div className="">
          {ids && (
            <>
              {ids.map((id) => {
                var account_data = {}
                for (let index in accounts) {
                  if (accounts[index].id === String(id)) {
                    account_data = accounts[index]
                  }
                }
                try {
                  return AccountCard(account_data, id, key)
                } catch {
                  console.log('Could Not Render Account #' + id)
                  return <></>
                }
              })}
            </>
          )}
        </div>
      </div>
    )
  }
  function ContactsDisplay({ contacts }) {
    function sortByRoleTitle(a: string, b: string) {
      const titles = [
        'Primary Contact',
        'Marketing or Creative Contact',
        'Billing Contact',
        'Sales Contact',
        'Operations Contact',
        'Invoice Contact',
      ]
      var a_num
      var b_num
      if (titles.includes(a)) {
        a_num = titles.indexOf(a)
      } else {
        a_num = 20
      }
      if (titles.includes(b)) {
        b_num = titles.indexOf(b)
      } else {
        b_num = 20
      }
      const result = a_num - b_num
      return result
    }

    return (
      <div className="flex h-fit w-full flex-row flex-wrap">
        {Object.keys(contacts)
          .sort(sortByRoleTitle)
          .map((key) => {
            return (
              <div key={'contacts_display_' + key}>
                {contacts[key] && <>{contactsBlock(key, contacts[key])} </>}
              </div>
            )
          })}
      </div>
    )
  }
  function contactsBlock(key, ids: string[]) {
    return (
      <div className="h-fit max-w-sm p-4" key={key + '_contactsBlock'}>
        <Typography
          placeholder="."
          variant="h5"
          color="blue-gray"
          className="px-4"
        >
          {key}
        </Typography>
        <hr className=" w-full border-2 border-blue-700" />
        <div className="">
          {ids.map((id) => {
            var account_data = {}
            for (let index in accounts) {
              if (accounts[index].id === String(id)) {
                account_data = accounts[index]
              }
            }
            try {
              return AccountCard(account_data, id, key)
            } catch {
              console.log('Could Not Render Account #' + id)
              return <></>
            }
          })}
        </div>
      </div>
    )
  }
  function AccountCard(account, acct_id, role_title) {
    return (
      <div className="p-2" key={acct_id + '_acct_card_' + role_title}>
        <Link to={'/view/account#' + acct_id}>
          <Card
            placeholder="."
            className=" h-30 flex w-80 flex-row overflow-hidden rounded-lg border border-gray-300 shadow-md"
          >
            {account.internal ||
            (account.avatar !== '' && account.avatar !== undefined) ? (
              <div className="w-1/3">
                <Avatar
                  placeholder="."
                  src={account?.avatar}
                  className="h-20 w-20 p-2"
                />
              </div>
            ) : (
              <div className="w-1/3">
                <UserIcon className="h-20 w-20 p-2" />
              </div>
            )}
            <div className="flex w-2/3 flex-col pl-2 pt-3">
              <Typography placeholder="." variant="h6" color="blue-gray">
                {account?.name}
              </Typography>
              {account.phone !== undefined && account.phone !== '' ? (
                <Typography placeholder="." variant="small" color="blue-gray">
                  {account?.phone}
                </Typography>
              ) : (
                <>
                  {account['Cell Phone'] !== undefined &&
                    account['Cell Phone'] !== '' && (
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        {account['Cell Phone']}
                      </Typography>
                    )}
                </>
              )}
              <Typography placeholder="." variant="small" color="blue-gray">
                {account?.email}
              </Typography>
            </div>
          </Card>
        </Link>
      </div>
    )
  }

  return (
    <>
      {contacts && roles && (
        <div className="flex w-full flex-row">
          {hasRoles && (
            <div className="h-fit w-1/2 p-2">
              <div className="ma-w-fit flex w-40 flex-row p-2">
                <Typography placeholder="." variant="h4">
                  Roles
                </Typography>
                {editMode &&
                  (permissions.edit_all_clients === true ||
                    (permissions.edit_my_client_roles && isMyClient) ||
                    permissions.edit_all_roles) && (
                    <div className="flex flex-row-reverse p-0 pl-2">
                      <Link to={'/edit/client/roles#' + String(client.id)}>
                        <IconButton placeholder="." variant="text">
                          <PencilIcon className="h-6 w-6"></PencilIcon>
                        </IconButton>
                      </Link>
                    </div>
                  )}
              </div>
              <div className="flex flex-row flex-wrap">
                <RolesDisplay roles={roles} />
              </div>
            </div>
          )}
          {hasContacts && (
            <div className="h-fit w-1/2 p-2">
              <div className="ma-w-fit flex w-40 flex-row p-2">
                <Typography placeholder="." variant="h4">
                  Contacts
                </Typography>
                {editMode &&
                  (permissions.edit_all_clients === true ||
                    (permissions.edit_my_client_contacts && isMyClient) ||
                    permissions.edit_all_contacts) && (
                    <div className="flex flex-row-reverse p-0 pl-2">
                      <Link to={'/edit/client/contacts#' + String(client.id)}>
                        <IconButton placeholder="." variant="text">
                          <PencilIcon className="h-6 w-6"></PencilIcon>
                        </IconButton>
                      </Link>
                    </div>
                  )}
              </div>
              <div className="flex flex-row flex-wrap">
                <ContactsDisplay contacts={contacts} />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex w-fit flex-col p-4">
        <div className="flex flex-row p-4 ">
          {!hasRoles && editMode && (
            <div className="p-2">
              <Link to={'/edit/client/roles#' + client.id}>
                <Button placeholder=".">Add Roles</Button>
              </Link>
            </div>
          )}
          {!hasContacts && editMode && (
            <div className="p-2">
              <Link to={'/edit/client/contacts#' + client.id}>
                <Button placeholder=".">Add Contacts</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
